import React from "react"
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import Footer from "../../../components/App/Footer"
import Seo from "../../../components/App/Seo"
import Inventur from "../../../components/ProductDetails/netstorsys/Inventur"

const InventurPage = () => {
  return (
    <Layout>
      <Seo title={"Smarte Inventur für maximale Bestandsgenauigkeit"}
           description={"Sichere Inventurprozesse in NETSTORSYS via permanenter Inventur " +
           "oder Stichtagsinventur."}
           image={"/images/og/netstorsys-main-og.png"}
      />
      <Navbar />
      <Inventur />
      <Footer />
    </Layout>
  );
}

export default InventurPage
