import React from "react"
import inventurHeader from "../../../assets/images/products/netstorsys/inventur_header.svg"
import wmLagerorte from "../../../assets/images/products/netstorsys/wm-lagerorte.svg"
import Sidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import WichtigeMerkmale from "../WichtigeMerkmale"
import LexikonBadgesRandomSection from "../../lexikon/LexikonBadgesRandomSection"
import { StaticImage } from "gatsby-plugin-image"
import FeatureSection from "../../App/FeatureSection"

const Inventur = () => {

  return (
    <>
      <section className="services-details-area ptb-100">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <img src={inventurHeader} alt="about" />
              </div>

              <div className="services-details-desc">
                <span className="sub-title">NETSTORSYS</span>
                <h1 className={"pt-3"}>Inventur </h1>
                <p>
                  NETSTORSYS ermöglicht die zielgerichtete Bestandsaufnahme aller Verkaufsartikel innerhalb eines
                  Warenwirtschaftssystems. Hierbei wird sowohl die permanente Inventur als auch die Stichtagsinventur
                  unterstützt.
                </p>
                <p>
                  Für den Inventurprozess sendet NETSTORSYS Inventuraufträge direkt auf mobile Erfassungsgeräte, mit deren
                  Hilfe die Mitarbeiter an die jeweiligen Inventurlagerplatz geführt werden um dort den aktuellen Bestand
                  festhalten zu können. Die Auswahl der zur Inventur herangezogenen Lagerplätze erfolgt dabei auf
                  laufwegsoptimierter Basis.
                </p>

                <WichtigeMerkmale items={[
                  "verschiedene Inventurstrategien",
                  "Reports für staatliche Organe",
                  "effiziente Inventurprozesse"
                ]} image={<img src={wmLagerorte}
                               alt="Inventur Merkmale"
                               className={"ml-lg-4 ml-0"} />} />

                <h3>Relevantes aus dem Lexikon</h3>
                <LexikonBadgesRandomSection product={"Netstorsys"}/>

              </div>
            </Col>

            <Col lg={4} md={12}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </section>
      <FeatureSection backgroundColorClass={"bg-f1f8fb"}
                      imagePosition={"left"}
                      image={<StaticImage
                        src="../../../assets/images/products/netstorsys/screenshots/website/ns_orders_framed.png"
                        alt="Auftragsübersicht Screenshot"
                        placeholder="blurred"
                        width={600}
                      />}
                      header={"Permanente Inventur"}
                      textBlock1={"In der Auftragsübersicht kann direkt über die Oberfläche von NETSTORSYS der Status eingesehen werden. "+
                      "Durch die Anbindung an bestehende Vorsystemen sind diese immer aktuell und spiegeln somit auch dessen "+
                      "Stand wieder. "+
                      "Im Detail ist direkt ersichtlich, welche Positionen eines Auftrags genügend Bestand aufweisen und welche "+
                      "nicht erfüllbar sind. Angepasst an Ihre Bedürfnisse lassen sich mit diesen Kennzahlen beispielsweise "+
                      "bestimmte Prozesse starten oder Warnungen auslösen. Somit können Sie zeitnah auf Bestandsprobleme "+
                      "reagieren oder diese sogar automatisch lösen lassen."}
                      textBlock2={<span>Die Permanente Inventur ist in NETSTORSYS ein integraler
                Bestandteil und liefert eine sehr gute Bestandsgenauigkeit im
                Lager. Durch die Einbindung der Inventur in tägliche Prozesse,
                wird kaum ein Mehraufwand generiert.</span>}
                      textBlock3={<span>Ziel der Permantenen Inventur ist einen Kommissioniervorgang
                mit einem Inventurvorgang zu koppeln. Der Picker ist in dem Fall
                durch den Kommissionierauftrag sowieso am Lagerort. Wenn eine
                definierbare Zeit seit der letzten Inventur des Lagerorts vergangen
                ist, wird er einfach aufgefordert direkt am Lagerort die Zählung
                durchzuführen und diese digital festzuhalten.</span>}
      />
    </>
  )
}

export default Inventur
